import naturaTins from '~assets/images/Naturatins-e-GovTocantins - Copy.png'
import Button from "@mui/material/Button"

export default function PublicHeader() {

  return (
    <div
      style={{ display: 'flex', height: '8vh', width: '100%', fontSmooth: 'antialiased', backgroundColor: '#fff' }}>
      <div
        style={{ justifyContent: 'flex-start', alignItems: 'center', width: '30%', height: '80%' }}>
        <img style={{ height: '8vh' }} src={naturaTins} alt="NaturaTins" />
      </div>

      {/*TODO colocar imagens com resolução melhor*/}

      <div
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <Button
          href='/'
          variant="contained"
          disableElevation
          sx={{
            mt: 2,
            mb: 2,
            mr: 2,
            ml: 2,
            color: '#3D4C76',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            textTransform: 'capitalize',
            fontSize: '13px',
            fontWeight: '800',
          }}
        >
          Home
        </Button>
        <Button
          href='/login'
          variant="contained"
          disableElevation
          sx={{
            mt: 2,
            mb: 2,
            mr: 10,
            ml: 2,
            color: '#3D4C76',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            textTransform: 'capitalize',
            fontSize: '13px',
            fontWeight: '800',
          }}
        >
          Entrar
        </Button>
      </div>
    </div>
  )
}
