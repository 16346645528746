/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import {toast, ToastOptions} from 'react-toastify'
import MainHeader from "~components/PublicHeader/publicHeader"
import logoEuPescador from '../../assets/images/logo-eu-pescador.png'
import {Container} from "@mui/material"
import {RecoverPassword} from "~services/api/userServices/login"
import EmailIcon from '@mui/icons-material/Email'
// eslint-disable-next-line @typescript-eslint/no-explicit-any

export default function Recovery() {

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    const toastConfig = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    } as ToastOptions

    await RecoverPassword(data.get('email').toString())
      .then(() => {
        toast.success('Email de redefinição enviado, verifique seu email!', toastConfig)
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          const message = err.response.data.message.toString()
          toast.error(message, toastConfig)
        } else {
          toast.error('Não foi possível alterar a senha!', toastConfig)
        }
      })
  }

  return (
    <>
      <MainHeader/>
      <Grid container component="main" sx={{margin: 0, padding: 0, bgcolor: '#E8E8E8'}}>
        <CssBaseline/>
        <Container sx={{display: 'flex'}}>
          <Grid item xs={12} sm={4} md={8}
                container
                direction="row"
                justifyContent="left"
                alignItems="center">
            <img style={{width: '40%'}} src={logoEuPescador} alt="Logo Eu Pescador"/>
          </Grid>


          <Grid item xs={12} sm={8} md={4}>
            <Box
              sx={{
                my: 8,
                mx: 4,
                mt: 15,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

              }}
            >
              <Typography component="h1" variant="h5" sx={{
                mt: 3,
                mb: 3,
                fontWeight: '900',
                fontSize: '42',
                color: '#3D4C76',
              }}>
                Recuperação da senha
              </Typography>

              <Typography sx={{
                mt: 3,
                mb: 3,
                fontWeight: '600',
                fontSize: '12',
                color: '#3D4C76',
                textAlign: 'center'
              }}>Digite seu e - mail cadastrado para receber um token e recuperar sua senha</Typography>

              <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1, width: '350px'}}>
                <TextField
                  placeholder="E-mail"
                  className="textfield"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  sx={{
                    fieldset: {borderColor: "#ffffff"}
                  }}
                  InputLabelProps={{
                    style: {color: '#111111'},
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon sx={{color: '#3d4c76'}}/>
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: '8px',
                      color: '#3d4c76',
                      backgroundColor: '#ffffff'
                    },
                  }}
                />

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    data-testid="login-button"
                    sx={{
                      mt: 2,
                      mb: 2,
                      backgroundColor: '#de8c3e',
                      borderRadius: '5px',
                      height: '50px',
                      width: '250px',
                      textTransform: 'capitalize',
                      fontSize: '18px',
                      fontWeight: '900',
                    }}
                  >
                    Enviar
                  </Button>

                </Box>


              </Box>
            </Box>
          </Grid>
        </Container>
      </Grid>
    </>
  )
}
