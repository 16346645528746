import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import MainHeader from "~components/PublicHeader/publicHeader"
import logoEuPescador from '../../assets/images/logo-eu-pescador.png'
import LockIcon from '@mui/icons-material/Lock'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Checkbox, Container, FormControlLabel } from "@mui/material"
import { CreateUser } from '~services/api/userServices/createUser'
import React, { useState } from 'react'
// eslint-disable-next-line @typescript-eslint/no-explicit-any

export default function Register() {
  const navigate = useNavigate()

  const [isChecked, setIsChecked] = useState(false)

  const checkHandler = () => {
    setIsChecked(!isChecked)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!isChecked) {
      alert("Usuário não aceitou o termo de uso!")
      return
    }

    const data = new FormData(event.currentTarget)

    const response = await CreateUser('', data.get('email').toString(), '', '', '', data.get('password').toString(), false)

    switch (response.status) {
      case 200:
        alert("Usuário acastrado com sucesso. Aguarde a liberação de acesso.")
        break
      case 409:
        alert("Email já cadastrado")
        break
      default:
        alert("Erro inesperado. Tente novamente mais tarde, se o erro persistir contate o administrador do sistema.su")
    }
  }

  return (
    <>
      <MainHeader />
      <Grid container component="main" sx={{ margin: 0, padding: 0, bgcolor: '#E8E8E8' }}>
        <CssBaseline />
        <Container sx={{ display: 'flex' }}>
          <Grid item xs={12} sm={4} md={8}
            container
            direction="row"
            justifyContent="left"
            alignItems="center">
            <img style={{ width: '40%' }} src={logoEuPescador} alt="Logo Eu Pescador" />
          </Grid>


          <Grid item xs={12} sm={8} md={4}>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

              }}
            >
              <Typography component="h1" variant="h5" sx={{
                mt: 3,
                mb: 3,
                fontWeight: '900',
                fontSize: '42',
                color: '#3D4C76'
              }}>
                Criar sua conta
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  placeholder="E-mail"
                  className="textfield"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  sx={{
                    fieldset: { borderColor: "#ffffff" }
                  }}
                  InputLabelProps={{
                    style: { color: '#111111' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon sx={{ color: '#3d4c76' }} />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: '8px',
                      color: '#3d4c76',
                      backgroundColor: '#ffffff'
                    },
                  }}
                />

                <TextField
                  placeholder="Senha"
                  className="textfield"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  sx={{
                    fieldset: { borderColor: "#ffffff" }
                  }}
                  InputLabelProps={{
                    style: { color: '#111111' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon sx={{ color: '#3d4c76' }} />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: '8px',
                      color: '#3d4c76',
                      backgroundColor: '#ffffff'
                    },
                  }}
                />

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Link href="/termsAccept" variant="body2" sx={{
                    color: '#3d4c76',
                    fontWeight: '850',
                    fontSize: '15px',
                  }}>
                    {'Abrir termos de uso'}
                  </Link>

                  <FormControlLabel
                    control={
                      <Checkbox name="termouso" id="termouso"
                        checked={isChecked}
                        onChange={checkHandler} />
                    }
                    label="Aceitar termo de uso"
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disableElevation
                    data-testid="login-button"
                    sx={{
                      mt: 2,
                      mb: 2,
                      backgroundColor: '#3d4c76',
                      borderRadius: '5px',
                      height: '50px',
                      width: '350px',
                      textTransform: 'capitalize',
                      fontSize: '18px',
                      fontWeight: '900',
                    }}
                  >
                    Criar
                  </Button>

                  <Link href="/recovery" variant="body2" sx={{
                    color: '#3d4c76',
                    fontWeight: '850',
                    fontSize: '15px',
                  }}>
                    {'Esqueci minha senha'}
                  </Link>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  mt={5}
                >
                  <p style={{
                    color: '#019055',
                    fontWeight: '850',
                    fontSize: '15px',
                  }}>
                    {'Já tem uma conta?'}
                  </p>

                  <Button
                    href="/login" // TODO implementar constante obter as rotas de um mesmo lugar
                    fullWidth
                    variant="contained"
                    disableElevation
                    data-testid="login-button"
                    sx={{
                      mt: 2,
                      mb: 2,
                      backgroundColor: '#019055',
                      borderRadius: '5px',
                      height: '50px',
                      width: '350px',
                      textTransform: 'capitalize',
                      fontSize: '18px',
                      fontWeight: '900',
                    }}
                  >
                    Entrar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Container>
      </Grid>
    </>
  )
}
