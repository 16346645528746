import {CircularProgress, Container, Grid, IconButton} from '@mui/material'
import {Edit} from '@mui/icons-material'

import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet'
import {TitlePage} from '~components/TitlePage/TitlePage'
import useLogs from 'hooks/useLogs'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {FishLogI} from '~services/api/fishLogServices/GetAllFishLogs'
import AdminHeader from "~components/AdminHeader/adminHeader"

const Home = () => {
  const navigate = useNavigate()
  const {logs} = useLogs()
  const [position, setPosition] = useState({
    latitude: -11,
    longitude: -48,
  })

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      })
    } else {
      toast.info('Geolocation is not supported by this browser.')
    }
  }, [])

  const filteredLogs = logs.filter(
    (log) =>
      log.coordenates && log.coordenates.latitude && log.coordenates.longitude
  )

  return (
    <Grid container sx={{backgroundColor: '#e8e8e8', flexDirection: 'column', height: '700px'}}>
      <AdminHeader/>

      <Container>
        <TitlePage title="Mapa"/>
      </Container>
      <div style={{height: '70%', width: '70%', backgroundColor: 'lightgrey', alignSelf: 'center',}}>

        {logs.length ? (
          <MapContainer
            center={[position.latitude, position.longitude]}
            zoom={13}
            scrollWheelZoom
            style={{
              height: '100%',
              width: '100%',
              borderRadius: '20px solid',
              alignSelf: 'center',
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {filteredLogs.map((log: FishLogI, index) => (
              <Marker
                key={index}
                position={[
                  Number(log.coordenates.latitude),
                  Number(log.coordenates.longitude),
                ]}
              >
                <Popup>
                  Localização do {log.name}, pesando {log.weight}g e medindo{' '}
                  {log.length}cm.
                  <IconButton
                    onClick={() => navigate(`/logs/${log.id}`)}
                    color="warning"
                  >
                    <Edit/>
                  </IconButton>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        ) : (
          <CircularProgress/>
        )}
      </div>
    </Grid>

  )
}

export default Home
