/* eslint-disable @typescript-eslint/no-unsafe-argument */
// Externs and Interns libs
import {useState, useEffect} from 'react'
import {Grid, Box, Modal, TextField, Button} from '@mui/material'
import Pagination from '@mui/material/Pagination'

// Components
import {FishCard} from '../../components/FishCard/FishCard'
import {FishModal} from '../../components/FishModal/FishModal'
import {TitlePage} from '../../components/TitlePage/TitlePage'

// Services
import {FishWikiArray, GetAllFishes, FishWiki} from '../../services/api/interfaces'
import AdminHeader from "~components/AdminHeader/adminHeader"
import {Container} from '@material-ui/core'
import Footer from "~components/Footer/Footer"
import InputAdornment from "@mui/material/InputAdornment"
import SearchIcon from "@mui/icons-material/Search"
import IconePeixeLaranja from "~assets/icons/peixe-laranja.png"
import {FishRecord} from "~components/FishRecord/FishRecord"

const Datas = () => {
  const [open, setOpen] = useState(false)
  const [openCadastrarPeixe, setOpenCadastrarPeixe] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [fishes, setFishes] = useState<FishWikiArray>()
  const [modalFish, setModalFish] = useState({} as FishWiki)
  const [count, setCount] = useState(0)

  const fishesPerPage = 10

  const handleOpen = () => {
    setOpen(true)
  }

  const handleSearch = (event) => {
    setSearch(event.target.value)
  }
  const handleClose = () => setOpen(false)
  const handleCloseCadastrarPeixe = () => setOpenCadastrarPeixe(false)

  useEffect(() => {
    const delay = setTimeout(() => {
      GetAllFishes(page, fishesPerPage, search)
        .then((res: FishWikiArray) => setFishes(res))
        .catch((e) => console.log(e))
    }, 500)

    return () => clearTimeout(delay)

  }, [page, search])

  function onPageChange(event, page: number) {
    setPage(page)
  }

  function handleOpenFishModal(res: FishWiki) {
    setModalFish(res)

    handleOpen()
  }

  const handleOpenCadastrarPeixe = () => {
    setOpenCadastrarPeixe(true)
  }

  return (
    <div style={{backgroundColor: '#e8e8e8'}}>
      <AdminHeader/>
      <Container>
        <Grid item xs={10} md={11}>
          <Box
            sx={{
              display: 'flex',
              justifyItems: 'center',
              alignItems: 'center',
              mb: '30px',
              mt: '30px',
              width: '100%'
            }}
          >
            <TitlePage title="Listagem de Peixes"/>
            <Button
              variant="text"
              onClick={() => {
                handleOpenCadastrarPeixe()
              }}
              sx={{
                borderRadius: '20px',
                fontWeight: 'bold',
                marginLeft: 'auto',
                marginRight: '16px',
                color: '#de8c3e',
                textTransform: 'none',
                fontSize: '16px',
                width: '300px'
              }}
            >
              <img style={{height: '30px'}} src={IconePeixeLaranja} alt="Peixe"/>
              Cadastrar Espécie
            </Button>
            <TextField
              value={search}
              onChange={handleSearch}
              placeholder="Pesquisar"
              className="textfield"
              margin="normal"
              id="pesquisar"
              name="pesquisar"
              autoFocus
              sx={{
                fieldset: {borderColor: "#ffffff"}
              }}
              InputLabelProps={{
                style: {color: '#111111'},
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{color: '#3d4c76'}}/>
                  </InputAdornment>
                ),
                style: {
                  borderRadius: '8px',
                  color: '#3d4c76',
                  backgroundColor: '#ffffff',
                },
              }}
            />
          </Box>

          <Grid container flexDirection='row' justifyContent='center'>
            {fishes &&
              (fishes.allFishWiki || []).map((res: FishWiki, index) => {
                return (
                  <Grid item key={index}>
                    <Box
                      onClick={() => {
                        handleOpenFishModal(res)
                      }}
                    >
                      <FishCard
                        fish={{
                          name: res.commonName,
                          imageUrl: res.photo == null ? 'https://imgur.com/ybTpCh6.png' : res.photo,
                        }}
                      ></FishCard>
                    </Box>
                  </Grid>
                )
              })}
          </Grid>
          {fishes && (
            <Pagination
              count={fishes.totalPages}
              page={page}
              onChange={onPageChange}
              style={{marginTop: '30px', justifyContent: 'center', display: 'flex', marginBottom: '15px'}}
              color="primary"
              size="small"
            />
          )}
        </Grid>
        <Modal open={open} onClose={handleClose}>
          <Box>
            <FishModal fish={modalFish} onClose={handleClose}></FishModal>
          </Box>
        </Modal>

        <Modal id="fishRecordModalBackground" open={openCadastrarPeixe} onClose={handleCloseCadastrarPeixe}>
          <Box>
            <FishRecord onClose={handleCloseCadastrarPeixe}/>
          </Box>
        </Modal>
      </Container>
      <Footer/>
    </div>
  )
}
export default Datas
