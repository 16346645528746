/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import {UserLogin} from '../../services/api/userServices/login'
import {ToastContainer, toast, ToastOptions} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import MainHeader from "~components/PublicHeader/publicHeader"
import peixeLogin from '../../assets/images/peixe-login.png'
import logoEuPescador from '../../assets/images/logo-eu-pescador.png'
import LockIcon from '@mui/icons-material/Lock'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

export default function Login() {
  const navigate = useNavigate()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    const toastConfig = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    } as ToastOptions

    await UserLogin(data.get('email').toString(), data.get('password').toString())
      .then((res) => {
        if (res.data.admin || res.data.superAdmin) {
          navigate('/admin', {replace: true})
          localStorage.setItem('UserData', JSON.stringify(res.data))
          window.location.href = '/dados'
          navigate('/dados', {replace: true})
        } else {
          toast.warning('Você não tem permissão para acessar essa página', toastConfig)
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          const message = err.response.data.message.toString()
          toast.error(message, toastConfig)
        } else {
          toast.error('Ooops! Algo deu errado! Tente novamente', toastConfig)
        }
      })
  }

  return (
    <>
      <ToastContainer/>
      <MainHeader/>
      <Grid container component="main" sx={{margin: 0, padding: 0, bgcolor: '#E8E8E8'}}>
        <CssBaseline/>

        <Grid item xs={12} sm={4} md={7.5}
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
          <img style={{width: '40%'}} src={logoEuPescador} alt="Logo Eu Pescador"/>
          <img style={{width: '40%'}} src={peixeLogin} alt="Peixe"/>
        </Grid>


        <Grid item xs={12} sm={8} md={4}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',

            }}
          >
            <Typography component="h1" variant="h5" sx={{
              mt: 3,
              mb: 3,
              fontWeight: '900',
              fontSize: '42',
              color: '#3D4C76'
            }}>
              Entrar na sua conta
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
              <TextField
                placeholder="E-mail ou telefone"
                className="textfield"
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{
                  fieldset: {borderColor: "#ffffff"}
                }}
                InputLabelProps={{
                  style: {color: '#111111'},
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon sx={{ color: '#3d4c76' }} />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '8px',
                    color: '#3d4c76',
                    backgroundColor: '#ffffff'
                  },
                }}
              />

              <TextField
                placeholder="Senha"
                className="textfield"
                margin="normal"
                required
                fullWidth
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{
                  fieldset: {borderColor: "#ffffff"}
                }}
                InputLabelProps={{
                  style: {color: '#111111'},
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon  sx={{ color: '#3d4c76'}} />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '8px',
                    color: '#3d4c76',
                    backgroundColor: '#ffffff'
                  },
                }}
              />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disableElevation
                  data-testid="login-button"
                  sx={{
                    mt: 2,
                    mb: 2,
                    backgroundColor: '#3d4c76',
                    borderRadius: '5px',
                    height: '50px',
                    width: '350px',
                    textTransform: 'capitalize',
                    fontSize: '18px',
                    fontWeight: '900',
                  }}
                >
                  Entrar
                </Button>

                <Link href="/recovery" variant="body2" sx={{
                  color: '#3d4c76',
                  fontWeight: '850',
                  fontSize: '15px',
                }}>
                  {'Esqueci minha senha'}
                </Link>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                mt={5}
              >
                <p style={{
                  color: '#de8c3e',
                  fontWeight: '850',
                  fontSize: '15px',
                }}>
                  {'Não possui uma conta?'}
                </p>

                <Button
                  href='/register'
                  fullWidth
                  variant="contained"
                  disableElevation
                  data-testid="login-button"
                  sx={{
                    mt: 2,
                    mb: 2,
                    backgroundColor: '#de8c3e',
                    borderRadius: '5px',
                    height: '50px',
                    width: '350px',
                    textTransform: 'capitalize',
                    fontSize: '18px',
                    fontWeight: '900',
                  }}
                >
                  Cadastrar
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
