import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'
import MainHeader from "~components/PublicHeader/publicHeader"
import { Box, Container } from "@mui/material"
import { CreateUser } from '~services/api/userServices/createUser'
import React, { useState } from "react"
import logoGoverno from '~assets/images/governo_to.png'
import logonatu from '~assets/images/naturatins2.png'

export default function TermsAccept() {
  const navigate = useNavigate()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const data = new FormData(event.currentTarget)

    const response = await CreateUser('', data.get('email').toString(), '', '', '', data.get('password').toString(), false)

    switch (response.status) {
      case 200:
        alert("Usuário acastrado com sucesso. Aguarde a liberação de acesso.")
        break
      case 409:
        alert("Email já cadastrado")
        break
      default:
        alert("Erro inesperado. Tente novamente mais tarde, se o erro persistir contate o administrador do sistema.su")
    }

  }
  return (
    <Grid container>
      <MainHeader />
      <Grid container component="main" sx={{ margin: 0, padding: 0, bgcolor: '#E8E8E8' }}>
        <CssBaseline />
        <Container sx={{ display: 'flex' }}>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Container sx={{ height: '80vh', marginTop: 2, padding: 0, bgcolor: '#fff', overflowY: 'scroll' }}>
              <div className="container" style={{ marginLeft: 5, marginRight: 10, textAlign: 'justify' }}>
                <div
                  style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>

                  <img style={{ height: '70px', alignSelf: 'flex-center' }} src={logoGoverno} alt="GovTocantins" />
                </div>
                <div
                  style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <img style={{ height: '70px', alignSelf: 'flex-center' }} src={logonatu} alt="Naturatins" />
                </div>


                <h1 style={{ textAlign: 'center' }}>POLÍTICA DE PRIVACIDADE</h1>
                <h2 style={{ textAlign: 'center' }}>EuPescador</h2>

                <h3 style={{ marginTop: 2 }}>I - INFORMAÇÕES EXISTENTES NA POLÍTICA</h3>
                <div className="term-text">
                  <p>
                    Nesta Política de Privacidade, o usuário do aplicativo será orientado sobre diversos aspectos essenciais, incluindo a mecânica operacional do serviço, as normativas que regem sua execução, as obrigações dos usuários ao acessá-lo, bem como as responsabilidades dos órgãos públicos na sua oferta.
                  </p>
                  <p>
                    Este documento também fornece canais de contato para esclarecimentos ou necessidade de atualizações, além de especificar a jurisdição competente para dirimir conflitos decorrentes de eventuais violações a esta política.
                  </p>
                  <p>
                    Por fim, detalha-se o procedimento adotado no tratamento dos dados pessoais, seja de forma automatizada ou manual, elucidando os propósitos específicos dessa ação. A política esclarece quais dados pessoais são essenciais para a disponibilização do serviço, como esses dados são capturados, a possibilidade de compartilhamento com entidades externas e as estratégias de segurança aplicadas para a salvaguarda dessas informações.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>II - ACEITAÇÃO DA POLÍTICA DE PRIVACIDADE</h3>
                <div className="term-text">
                  <p>
                    Ao acessar e utilizar os serviços oferecidos, o usuário atesta que realizou a leitura, compreendeu integralmente as Políticas de Privacidade associadas ao Aplicativo, e expressa seu acordo em se comprometer e aderir aos termos estabelecidos.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>III - DEFINIÇÕES</h3>
                <div className="term-text">
                  <p>
                    Para melhor compreensão deste documento, nesta Política de Privacidade, consideram-se:
                  </p>

                  <ul style={{ marginLeft: 50 }}>
                    <li><text className="sub-text">Agentes de tratamento:</text> o controlador e o operador;</li>
                    <li><text className="sub-text">Consentimento:</text> Manifestação livre, informada e inequívoca pela qual o titular concorda
                      com o tratamento de seus dados pessoais para uma finalidade determinada;</li>
                    <li><text className="sub-text">Dado pessoal:</text> informação relacionada a pessoa natural identificada ou identificável;</li>
                    <li><text className="sub-text">Dado pessoal sensível:</text> dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;</li>
                    <li><text className="sub-text">Operador:</text> pessoa natural ou jurídica, de direito público ou privado, que realiza o
                      tratamento de dados pessoais em nome do controlador;</li>
                    <li><text className="sub-text">Controlador:</text> pessoa  natural  ou  jurídica,  de  direito  público  ou  privado,  a  quem
                      competem as decisões referentes ao tratamento de dados pessoais.</li>
                    <li><text className="sub-text">Encarregado de Dados:</text> pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD). O Ministério da Saúde não tem um encarregado
                      nomeado até o presente momento.</li>
                    <li><text className="sub-text">Titular:</text> pessoa natural a quem se referem os dados pessoais que são objeto de tratamento.</li>
                    <li><text className="sub-text">Tratamento:</text>  comunicação,  difusão,  transferência  internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entes privados.</li>
                    <li><text className="sub-text">Lei Geral de Proteção de Dados:</text> Lei Federal n. 13.709, de 14 de agosto de 2018, que
                      dispõe sobre o tratamento de dados pessoais, inclusive nos meios digitais, por pessoa natural ou por pessoa jurídica de direito público ou privado, com o objetivo de proteger
                      os direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural.</li>
                    <li><text className="sub-text">Usuários  (ou  "Usuário",  quando  individualmente  considerado):</text> todas  as  pessoas
                      naturais que utilizarem o Aplicativo.</li>
                    <li><text className="sub-text">ANPD:</text> autoridade nacional de proteção de dados pessoais é órgão da administração
                      pública responsável por zelar, implementar e fiscalizar o cumprimento desta Lei em todo
                      o território nacional.</li>
                  </ul>
                </div>

                <h3 style={{ marginTop: 4 }}>V - DESCRIÇÃO DO SERVIÇO OFERECIDO PELO APLICATIVO “EUPESCADOR”</h3>
                <div className="term-text">
                  <p>
                    O aplicativo "EuPescador" representa uma inovação no Brasil, fruto da colaboração entre o Instituto Natureza do Tocantins NATURATINS e a Agência de Tecnologia de Informação - ATI. Esta ferramenta digital visa enriquecer o conhecimento dos cidadãos sobre a diversidade de espécies pesqueiras presentes nas bacias dos rios Tocantins e Araguaia. Além de oferecer informações educativas  sobre  as  espécies,  permite  aos  usuários  registrar  suas  atividades  de  pesca, contribuindo para a gestão sustentável dos recursos pesqueiros da região.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>VI - DIREITO DOS USUÁRIOS DO APLICATIVO</h3>
                <div className="term-text">
                  <p>
                    Como beneficiário deste serviço, você detém uma série de direitos fundamentais destinados a assegurar a sua autonomia e proteção de seus dados pessoais. Esses direitos incluem, mas não se limitam ao acesso completo às informações que concernem a você e que são mantidas pelo
                    serviço, a correção de quaisquer dados imprecisos, a exclusão de dados sob certas condições, a limitação do processamento de seus dados, e a oposição a certos usos dos seus dados. Você tem o direito de ser informado sobre a coleta e uso de seus dados de forma clara e acessível, bem como o direito de retirar seu consentimento a qualquer momento para futuros tratamentos de
                    dados, garantindo assim o controle sobre suas informações pessoais.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>VII - OBRIGAÇÕES DOS USUÁRIOS DO SERVIÇO</h3>
                <div className="term-text">
                  <p>
                    Como usuário do aplicativo "EuPescador", é fundamental que você compreenda e respeite suas
                    obrigações para garantir a segurança e a eficácia do serviço. Isso inclui o uso responsável e ético
                    do aplicativo, a precisão e veracidade das informações fornecidas ao registrar as espécies
                    pescadas, e o compromisso de não utilizar o serviço para fins ilícitos ou prejudiciais ao meio
                    ambiente e à biodiversidade das bacias dos rios Tocantins e Araguaia.
                  </p>
                  <p>
                    Você deve também respeitar os termos de uso estabelecidos pelo NATURATINS e pela ATI,
                    evitando ações que possam comprometer a integridade, disponibilidade e confidencialidade dos
                    dados  coletados  e  processados  pelo  aplicativo.  Incluindo:  a  proibição  de  compartilhar
                    indevidamente seu acesso com terceiros, utilizar softwares para alteração ou extração de dados
                    de forma não autorizada, e a inserção de dados falsos ou enganosos, dentre outros.
                  </p>
                  <p>
                    Ao cumprir com estas obrigações, você contribui para a preservação dos recursos pesqueiros,
                    auxilia na coleta de dados importantes para estudos ambientais, e assegura a preservação
                    sustentável e responsável das espécies de peixes dos rios Tocantins e Araguaia.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>VIII - EXONERAÇÃO DE RESPONSABILIDADE DO ÓRGÃO</h3>
                <div className="term-text">
                  <p>
                    É importante que os usuários estejam cientes das limitações da responsabilidade do Órgão em
                    relação à segurança da informação e ao uso do aplicativo. Neste contexto, o Órgão não poderá
                    ser responsabilizado pelos seguintes aspectos:
                  </p>

                  <ul style={{ marginLeft: 50 }}>
                    <li><text className="sub-text">Infecção ou Invasão do Equipamento:</text> Danos causados por malware, vírus ou por ataques
                      cibernéticos direcionados ao equipamento do usuário que afetem a integridade ou o desempenho do aplicativo.</li>
                    <li><text className="sub-text">Equipamento Avariado:</text> Problemas ou falhas no hardware do usuário que prejudiquem
                      o acesso ou a funcionalidade do aplicativo.</li>
                    <li><text className="sub-text">Proteção do dispositivo portátil e das Informações:</text> A responsabilidade pela segurança
                      do seu dispositivo informático e pela proteção das informações armazenadas nele,
                      incluindo medidas contra o acesso não autorizado ou a perda de dados.</li>
                    <li><text className="sub-text">Abuso de Uso:</text> Consequências advindas do uso inadequado ou abusivo do aplicativo por
                      parte do usuário.</li>
                    <li><text className="sub-text">Monitoração Clandestina:</text> Incidências de espionagem ou monitoramento não autorizado
                      do dispositivo do usuário, que estejam fora do controle do Órgão.</li>
                    <li><text className="sub-text">Vulnerabilidades dos Sistemas dos Usuários:</text> Impactos resultantes de vulnerabilidades
                      ou instabilidades nos sistemas operacionais ou softwares instalados nos dispositivos dos
                      usuários.</li>
                    <li><text className="sub-text">Perímetro Inseguro:</text> Comprometimentos de segurança que ocorram em redes ou
                      conexões de internet inseguras utilizadas pelo usuário para acessar o aplicativo.</li>
                  </ul>
                  <p>É de suma importância que os usuários tomem as devidas precauções para garantir a segurança
                    de seus dispositivos e dados ao utilizar o aplicativo "EuPescador". O Órgão compromete-se a
                    manter o aplicativo seguro dentro de suas capacidades, mas a segurança do dispositivo do
                    usuário e a proteção de suas informações pessoais também dependem de práticas de segurança
                    adequadas por parte do usuário.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>IX - RESPONSABILIDADES DA ADMINISTRAÇÃO PÚBLICA COM OS DADOS DOS USUÁRIOS</h3>
                <div className="term-text">
                  <p>
                    A Administração Pública, atuando como custodiante das informações pessoais coletadas pelo
                    aplicativo "EuPescador", assume a responsabilidade de aderir rigorosamente à legislação vigente
                    sobre a proteção de dados pessoais. Este compromisso envolve a implementação de práticas e
                    políticas que assegurem a integridade, a confidencialidade e a disponibilidade das informações
                    dos usuários, respeitando sua privacidade e liberdades fundamentais.
                  </p>
                  <p>
                    As principais responsabilidades da Administração Pública neste contexto incluem:
                  </p>

                  <ul style={{ marginLeft: 50 }}>
                    <li><text className="sub-text">Cumprimento Legal:</text> Garantir que todas as operações de tratamento de dados pessoais
                      estejam em conformidade com as leis aplicáveis de proteção de dados, como a Lei Geral
                      de Proteção de Dados - LGPD e outros regulamentos relevantes.</li>
                    <li><text className="sub-text">Transparência:</text> Informar os usuários de maneira clara e acessível sobre como seus dados
                      pessoais são coletados, utilizados, compartilhados e armazenados, inclusive qualquer
                      transferência de dados para terceiros, quando aplicável.</li>
                    <li><text className="sub-text">Segurança da Informação:</text> Implementar medidas técnicas e administrativas adequadas
                      para proteger os dados pessoais contra acessos não autorizados, alterações indevidas,
                      divulgação ou destruição ilegal, e qualquer forma de tratamento inadequado.</li>
                    <li><text className="sub-text">Direitos dos Titulares:</text> Assegurar o respeito aos direitos dos usuários enquanto titulares
                      dos dados, incluindo o direito de acesso, correção, exclusão, e oposição ao tratamento
                      de seus dados, entre outros previstos em lei.</li>
                    <li><text className="sub-text">Notificação de Incidentes:</text> Comprometer-se a comunicar os usuários e as autoridades
                      competentes em tempo hábil sobre qualquer violação de segurança que possa acarretar
                      riscos aos direitos e liberdades individuais.</li>
                    <li><text className="sub-text">Avaliação de Impacto à Proteção de Dados:</text> Realizar, quando necessário, avaliações de
                      impacto sobre a proteção de dados pessoais para atividades de tratamento que possam
                      gerar riscos elevados à privacidade dos usuários.</li>
                    <li><text className="sub-text">Cooperação com Autoridades Reguladoras:</text> Colaborar com as autoridades de proteção
                      de dados e outras entidades reguladoras, fornecendo todas as informações necessárias
                      para  a  execução  de  suas  funções,  especialmente  em  casos  de  investigações  ou
                      auditorias.</li>
                    <li><text className="sub-text">Educação e Conscientização:</text> Promover iniciativas de educação e conscientização sobre
                      proteção de dados tanto para os usuários do aplicativo quanto para os funcionários e
                      parceiros envolvidos no tratamento de dados pessoais.</li>
                  </ul>

                  <p>
                    Este compromisso reflete a importância que a Administração Pública atribui à privacidade e à
                    proteção dos dados pessoais dos usuários, alinhando-se com as melhores práticas nacionais e
                    internacionais em matéria de segurança da informação e privacidade de dados.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>X - AGENTES DE TRATAMENTO DE DADOS E RESPONSABILIDADES NO APLICATIVO EUPESCADOR</h3>
                <div className="term-text">
                  <p>
                    No âmbito do Aplicativo "EuPescador", a gestão e o tratamento de dados pessoais são regidos
                    pela  Lei  Geral  de  Proteção  de  Dados  -  LGPD  que  estabelece  claramente  os  papéis  e
                    responsabilidades dos agentes de tratamento de dados, incluindo o <text className="sub-text">controlador</text> e o
                    <text className="sub-text">operador</text>.
                  </p>

                  <p>
                    <text className="sub-text">
                      Definição de Controlador
                    </text>
                  </p>
                  <p>
                    De acordo com o artigo 5º, inciso VI, da LGPD, o controlador é a pessoa natural ou jurídica, de
                    direito público ou privado, que tem a autoridade para tomar decisões referentes ao tratamento
                    de dados pessoais. Este papel envolve a determinação dos propósitos e dos meios pelos quais
                    os dados pessoais são processados.
                  </p>

                  <p>
                    <text className="sub-text">
                      Responsabilidade do NATURATINS
                    </text>
                  </p>
                  <p>
                    Para o Aplicativo "EuPescador", o Instituto Natureza do Tocantins, uma entidade de direito
                    público, assume o papel de controlador. Isso significa que o NATURATINS é responsável por todas
                    as decisões relacionadas ao tratamento de dados pessoais coletados e processados pelo
                    aplicativo. Essas responsabilidades incluem:
                  </p>

                  <ul style={{ marginLeft: 50 }}>
                    <li>Definir as finalidades para as quais os dados pessoais dos usuários são coletados, como
                      o aprendizado sobre espécies de peixes nas bacias dos rios Tocantins e Araguaia e o
                      registro das atividades de pesca realizadas pelos usuários.</li>
                    <li>Determinar os meios de tratamento desses dados, garantindo sua proteção e segurança
                      em conformidade com a legislação vigente.</li>
                    <li>Assegurar que os direitos dos titulares dos dados sejam respeitados, oferecendo
                      mecanismos para que os usuários possam exercer seus direitos de acesso, correção,
                      exclusão, entre outros previstos pela LGPD.</li>
                    <li>Implementar medidas de segurança adequadas para proteger os dados pessoais contra
                      acessos  não  autorizados,  perda,  alteração  ou  qualquer  forma  de  tratamento
                      inadequado.</li>
                  </ul>

                  <p>
                    O NATURATINS, como controlador, compromete-se a manter a transparência com os usuários do
                    Aplicativo "EuPescador" sobre o tratamento de seus dados pessoais, fornecendo informações
                    claras e acessíveis sobre suas práticas de privacidade. Além disso, reafirma seu compromisso em
                    adotar as melhores práticas e medidas de segurança para garantir a proteção dos dados pessoais
                    e a privacidade dos usuários, em conformidade com a LGPD e outras legislações aplicáveis.
                  </p>

                  <p>
                    <text className="sub-text">
                      Operadores no Aplicativo "EuPescador"
                    </text>
                  </p>
                  <p>
                    Dentro da estrutura operacional e de gestão do Aplicativo "EuPescador", as funções de
                    operadores de dados são desempenhadas internamente pelo Instituto Natureza do Tocantins -
                    NATURATINS e pela Agência de Tecnologia da Informação - ATI. Ambas as entidades trabalham
                    em estreita colaboração para garantir a eficácia, segurança e conformidade do tratamento de
                    dados pessoais de acordo com a Lei Geral de Proteção de Dados - LGPD e outras normativas
                    aplicáveis.
                  </p>

                  <p>
                    <text className="sub-text">
                      Definição de Operador
                    </text>
                  </p>
                  <p>
                    Conforme estabelecido pela LGPD, o operador é a pessoa natural ou jurídica, de direito público
                    ou privado, que realiza o tratamento de dados pessoais em nome do controlador. No contexto
                    do Aplicativo "EuPescador", o NATURATINS, atuando como controlador, delega certas operações
                    de tratamento de dados à ATI, que opera sob sua orientação e em conformidade com as políticas
                    estabelecidas e, quando atua na condição de operador, executa tarefas rotineiras concernente
                    aos dados  coletados.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>XI - RESPONSABILIDADES CONJUNTAS DE NATURATINS E ATI</h3>
                <div className="term-text">
                  <ul style={{ marginLeft: 50 }}>
                    <li><text className="sub-text">Implementação de Medidas de Segurança:</text> NATURATINS e ATI são responsáveis por
                      implementar e manter medidas de segurança técnicas e administrativas aptas a proteger
                      os dados pessoais dos usuários contra acessos não autorizados, situações de perda,
                      alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.</li>

                    <li><text className="sub-text">Processamento de Dados:</text> Ambas as entidades asseguram que o processamento de
                      dados  pessoais  coletados  pelo  Aplicativo  "EuPescador"  é  realizado  de  maneira a respeitar os princípios e direitos estabelecidos pela LGPD, incluindo a finalidade,
                      adequação, necessidade, e transparência.</li>
                    <li><text className="sub-text">Suporte aos Direitos dos Titulares de Dados:</text> NATURATINS e ATI comprometem-se a
                      facilitar o exercício dos direitos dos titulares dos dados, como acesso, correção, exclusão,
                      e oposição ao tratamento, assegurando que os usuários possam efetivamente gerenciar
                      suas informações pessoais.</li>
                    <li><text className="sub-text">Auditoria e Conformidade:</text> É responsabilidade conjunta do NATURATINS e da ATI garantir
                      a conformidade com a LGPD e outras normas aplicáveis ao tratamento de dados
                      pessoais, adotando correções e melhorias contínuas nos processos de segurança e
                      privacidade.</li>
                  </ul>
                </div>

                <h3 style={{ marginTop: 4 }}>XII - COMPROMISSO COM A PRIVACIDADE E A PROTEÇÃO DE DADOS</h3>
                <div className="term-text">
                  <p>
                    O NATURATINS, juntamente com a ATI, reafirma seu compromisso com a proteção da privacidade
                    e dos dados pessoais dos usuários do Aplicativo "EuPescador". Este compromisso reflete a
                    importância  dada  à  segurança  da  informação  e  ao  respeito  à  privacidade  dos  usuários,
                    estabelecendo uma base sólida para a confiança e a transparência no uso do aplicativo.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>XIII – É REALIZADO O TRATAMENTO DOS SEGUINTES DADOS PESSOAIS:</h3>
                <div className="term-text">
                  <ul style={{ marginLeft: 50 }}>
                    <li>Nome completo;</li>
                    <li>Endereço de e-mail;</li>
                    <li>Número de telefone;</li>
                    <li>Cidade e Estado de residência.</li>
                  </ul>
                  <p>
                    Para garantir o pleno funcionamento e a melhor experiência de uso do aplicativo, certos recursos
                    ou informações poderão ser solicitados no momento da primeira utilização ou durante a
                    instalação do aplicativo. Estas solicitações serão devidamente notificadas através do sistema
                    operacional de seu dispositivo móvel. Entre os acessos requisitados, incluem-se:
                  </p>


                  <ul style={{ marginLeft: 50 }}>
                    <li><text className="sub-text">Acesso à Rede:</text> Necessário para que o aplicativo possa se conectar à Internet, seja
                      através de dados móveis ou Wi-Fi, permitindo a atualização e sincronização de
                      informações em tempo real.</li>
                    <li><text className="sub-text">Identificação do Dispositivo:</text> Utilizado para garantir a segurança e a unicidade de cada
                      usuário, otimizando a experiência de uso e permitindo a personalização de serviços.</li>
                    <li><text className="sub-text">Câmera, Fotos, Mídia e Arquivos de Áudio e Vídeo:</text> Essencial para possibilitar o
                      compartilhamento e o registro de capturas, observações e outros conteúdos relevantes
                      pelo usuário, enriquecendo a interação com o aplicativo e contribuindo para a base de
                      dados coletiva.</li>
                  </ul>
                </div>

                <h3 style={{ marginTop: 4 }}>XIV - FINALIDADE DOS DADOS PESSOAIS SOLICITADOS NO CADASTRO</h3>
                <div className="term-text">
                  <ul style={{ marginLeft: 50 }}>
                    <li><text className="sub-text">Nome  Completo:</text> Essencial  para  a  identificação  precisa  do  usuário,  facilitando  a
                      interação personalizada e a gestão de contas dentro do serviço;</li>
                    <li><text className="sub-text">Endereço de E-mail:</text> Utilizado tanto para a identificação do usuário quanto para o envio
                      de  notificações  importantes,  atualizações  do  serviço  e  comunicações  essenciais,
                      assegurando um canal direto e eficiente de contato;</li>
                    <li><text className="sub-text">Número de Telefone:</text> Fundamental para estabelecer uma comunicação direta e eficaz
                      com o usuário. Este dado é particularmente importante para o NATURATINS na obtenção
                      de informações detalhadas sobre registros de peixes no aplicativo;</li>
                    <li><text className="sub-text">Cidade e Estado de Residência:</text> Coletados com o propósito de aprimorar e personalizar
                      a experiência do usuário no aplicativo. Esses dados permitem ao serviço identificar qual
                      localidade concentra o maior número de pescadores.</li>
                  </ul>
                </div>

                <h3 style={{ marginTop: 4 }}>XV - COMPARTILHAMENTO DOS DADOS PESSOAIS COLETADOS</h3>
                <div className="term-text">
                  <p>
                    Os dados pessoais coletados pelo serviço "EuPescador" não serão compartilhados no momento
                    com  quaisquer  entidades  externas,  seja  com  fábricas  de  softwares  ou  serviços  de
                    armazenamento em nuvem.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>XVI - CANAL PARA RECLAMAÇÕES</h3>
                <div className="term-text">
                  <p>
                    Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, todos os titulares de
                    dados têm o direito de apresentar reclamação à Autoridade Nacional de Proteção de Dados,
                    conforme  estabelecido  pela  legislação  brasileira.  No  entanto,  no  contexto  do  aplicativo
                    "EuPescador", qualquer reclamação judicial será resolvida exclusivamente pela Justiça Estadual,
                    de acordo com a localização do domicílio do usuário. Esta medida visa facilitar o acesso do
                    usuário aos meios judiciais, garantindo que suas questões sejam tratadas de forma eficiente e
                    acessível dentro do sistema legal brasileiro.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>XVII - ATUALIZAÇÃO DA POLÍTICA DE PRIVACIDADE</h3>
                <div className="term-text">
                  <p>
                    <text className="sub-text">
                      Atualização  da  Política  de  Privacidade:
                    </text>
                    A  última  atualização  desta  política  ocorreu  em
                    25/02/2024. O NATURATINS pode alterar estas normas a qualquer momento para refletir
                    novidades ou mudanças no aplicativo, com as alterações entrando em vigor imediatamente após
                    sua publicação, após a devida comunicação aos usuários.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>XVIII - CONSENTIMENTO</h3>
                <div className="term-text">
                  <p>
                    Ao usar o aplicativo “EuPescador”, o usuário aceita e concorda com todos os termos da Política
                    de Privacidade.
                  </p>
                </div>

                <h3 style={{ marginTop: 4 }}>XIX - CONTATO PARA SOLUCIONAR DÚVIDAS</h3>
                <div className="term-text">
                  <p>
                    Caso o usuário tenha alguma dúvida sobre esta Política de Privacidade, ele poderá entrar em
                    contato pelo e-mail ouvidoria.naturatins@gmail.com ou pelo telefone (63) 3218-2600.
                  </p>
                </div>
              </div>
            </Container>
          </Box>
        </Container>
      </Grid >
    </Grid >
  )
}
